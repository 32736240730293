import React, { lazy, useCallback, useEffect, useMemo, useState } from "react";
import { Course } from "./interfaces";
// import { Image } from "components/Image/Image";
import Images from "next/image";
import { LogoChip } from "components/Chip/LogoChip";
import { formatRating, numberWithCommas } from "lib/helper";
import { IconStar } from "components/Icon/IconStar";
import useTranslation from "next-translate/useTranslation";
import { Chip } from "components/Chip/Chip";
// import { VideoJS } from "modules/Course/CourseVideoJs";
import { useRouter } from "next/router";
import { trackEvent } from "lib/amplitude";
import {
  isGokAresURL,
  isMenkesURL,
  isMenpanURL,
  isPpdsmAparaturURL,
  isWonderfulLearningURL,
  useIsSubdomain,
  useIsTenant,
} from "lib/host";
import { AccordionWithImage } from "components/Accordion/AccordionWithImage";
import Link from "next/link";
import { useAuthStore } from "modules/Auth/authStore";
import Button from "components/Button";
import { SkeletonRect } from "components/Skeleton/Skeleton";
import { IconCheckCircle } from "components/Icon/IconCheckCircle";
import { useCartStore } from "modules/Business/Selection/cartStore";
import { IconPlusCircle } from "components/Icon/IconPlusCircle";
import { IconPlayCircle } from "components/Icon/IconPlayCircle";
import dynamic from "next/dynamic";
import { useJPTrackers } from "modules/Tenant/homeTenantApi";
import { useLearningHourStore } from "modules/Tenant/learningHourStore";

const VideoJS = dynamic(
  () => import("modules/Course/CourseVideoJs").then((mod) => mod.VideoJS),
  {
    ssr: false,
  }
);
const Image = dynamic(
  () => import("components/Image/Image").then((mod) => mod.Image),
  {
    ssr: false,
  }
);

type PropsCard = Course & {
  isUncheck?: boolean;
  isHide?: boolean;
  isRow?: boolean;
  isMyClass?: boolean;
  isSquare?: boolean;
  tracker?: any;
  wrapClass?: string;
  isRecommendPage?: boolean;
  onClickFunction?: () => void;
  isModal?: boolean;
};

const CardCourseWithLesson: React.FC<PropsCard> = ({
  isUncheck = false,
  isHide = false,
  className = "",
  isRow = false,
  isMyClass = false,
  isSquare = false,
  tracker,
  wrapClass = "",
  isRecommendPage = false,
  onClickFunction,
  isModal = false,
  ...props
}) => {
  const [chapters, setChapters] = useState([]);
  const [countLesson, setCountLesson] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const institutionImage = useMemo(() => {
    if (props?.institution?.image_url) return props?.institution?.image_url;
    if (
      typeof props?.institution == "string" &&
      (props?.institution as any).split("||").length > 1 &&
      (props?.institution as any).split("||")[1].includes("https")
    )
      return (props?.institution as any).split("||")[1];
    if (typeof props?.institution == "object")
      return props?.institution?.image_url;
    return null;
  }, [props?.institution]);

  const institutionName = useMemo(
    () => props.institution?.name || props.institution?.split("||")[0] || "",
    [props.institution]
  );

  const { t } = useTranslation("common");
  const isB2B = useIsTenant() || useIsSubdomain();
  const isBusiness = !isB2B;

  const isGokAresTenant =
    typeof window !== "undefined" && isGokAresURL(window.location.hostname);

  const isPpdsmAparaturTenant =
    typeof window !== "undefined" &&
    isPpdsmAparaturURL(window.location.hostname);

  const isMenpanTenant =
    typeof window !== "undefined" && isMenpanURL(window.location.hostname);

  const isWonderfulLearningTenant =
    typeof window !== "undefined" &&
    isWonderfulLearningURL(window.location.hostname);

  const isKemkesTenant =
    typeof window !== "undefined" && isMenkesURL(window.location.hostname);

  const { learningHour } = useLearningHourStore();

  useEffect(() => {
    if (isRow && props?.chapters && props?.chapters.length) {
      setLoading(true);

      const newChapters = props?.chapters.map((el: any) => {
        const allLesson =
          el?.lessons && el?.lessons.length
            ? el?.lessons.map((items: any) => ({
                image: props?.image_cover_url,
                course_slug: props.slug,
                ...items,
              }))
            : [];

        return {
          name: el.name,
          image: props?.image_cover_url,
          lessons: allLesson,
        };
      });

      let counting: any = props?.chapters.flatMap(
        (el: any) => el?.lessons && el?.lessons.length
      );

      setCountLesson(counting.length);
      setChapters(newChapters);
      setLoading(false);
    }
  }, [props.chapters, isRow]);

  const events = useMemo(
    () => ({
      event: "course_hover_a",
      property: {
        course_name: props?.name ?? "",
        course_slug: props?.slug ?? "",
        section_name: props?.sectionName ?? "",
        institution_name:
          (props as any)?.institution_name ?? props.institution?.name ?? "",
        index: props?.index ?? 0,
        page: `B2B Homepage`,
      },
    }),
    [props]
  );

  const [isHovering, setIsHovered] = useState(null);
  const [videoURL, setVideoURL] = useState("");
  const [timeout, setModalTimeout] = useState(null);
  const [videoTimeout, setVideoTimeout] = useState(null);

  useEffect(() => {
    setVideoURL((props as any)?.hover_content_url);
  }, [isHovering]);

  const mouseEnterHandler = useCallback(() => {
    timeout && clearTimeout(timeout);
    videoTimeout && clearTimeout(timeout);
    setModalTimeout(
      setTimeout(() => {
        trackEvent({
          ...(events as any),
        });
      }, 3000)
    );

    setVideoTimeout(
      setTimeout(() => {
        setIsHovered(isHide ? false : true);
      }, 500)
    );
  }, [events, isHide, timeout, videoTimeout]);

  const mouseLeaveHandler = useCallback(() => {
    timeout && clearTimeout(timeout);
    videoTimeout && clearTimeout(videoTimeout);
    setIsHovered(false);
  }, [timeout, videoTimeout]);

  const router = useRouter();
  const hasBusinessCourse =
    typeof router.query.hasBusinessCourse == "string"
      ? router.query.hasBusinessCourse
      : "false";

  const { loginState, currentUser } = useAuthStore((state) => ({
    loginState: state.loginState,
    currentUser: state.currentUser,
  }));

  const isSectionPage = router && router.pathname == "/section/[sectionSlug]";
  const isMyClassPage = router && router.pathname == "/my-class";
  const isSearchPage =
    typeof router.query.q == "string" ? router.query.q : null;
  const updateCart = useCartStore((state) => state.updateCart);

  // let content = () => null;

  const lengthOfText =
    props?.skills.length > 0
      ? props?.skills
          ?.slice(0, 2)
          .flatMap((el) => el.name)
          .join(" ").length
      : null;

  let index = isRow ? (lengthOfText <= 100 ? 3 : 2) : 1;
  let lengthOfSkill =
    props?.skills?.length > index ? props?.skills?.length - index : null;

  const hasUserPaid: boolean = (props as any).is_paid;
  const hasCertificate: boolean = (props as any).has_certificate;
  const hasPaywall: boolean =
    (props as any).is_paywall_enabled === undefined
      ? true
      : (props as any).is_paywall_enabled;
  const isCourseFinished =
    props.enrollment_progress?.finished === props.enrollment_progress?.total;
  const isEnterprisePlus =
    currentUser?.tenant !== undefined &&
    [
      "Enterprise Plus",
      "Pro Plus",
      `Pro Custom (${currentUser?.referral})`,
    ].includes((currentUser?.tenant as any)?.plan?.name);

  let content = useCallback(
    () =>
      videoURL ? <VideoJS isHovering={isHovering} url={videoURL} /> : null,
    [videoURL, isHovering]
  );

  return (
    <div
      className={(!isRow ? "cursor-pointer" : "") + " relative h-full w-full"}
      onClick={
        onClickFunction
          ? onClickFunction
          : () => {
              if (!isRow) {
                if ((props as any).onSelect) {
                  updateCart(props);
                } else {
                  router.push(
                    loginState == "LOGIN"
                      ? isRecommendPage
                        ? {
                            pathname: "/r/[page]",
                            query: {
                              page: `/course/${props.slug}`,
                            },
                          }
                        : {
                            pathname: "/course/[courseSlug]",
                            query: { courseSlug: props?.slug },
                          }
                      : {
                          pathname: "/login",
                          query: {
                            url: encodeURIComponent(`/course/${props?.slug}`),
                          },
                        }
                  );
                }
              }

              if (tracker) {
                trackEvent(tracker);
              }
            }
      }
    >
      <div
        className={`flex gap-8 h-fit ${
          isRow ? "flex-col w-full md:flex-row" : "flex-col"
        }`}
      >
        <div
          className={`relative cursor-pointer overflow-hidden aspect-[16/9] ${
            isHovering ? "" : isSquare ? "" : "rounded-8"
          } ${
            isRow
              ? "md:w-[40%] w-full h-full"
              : `${wrapClass} h-[200px] md:h-full`
          }`}
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
          onClick={() => {
            if (isRow) {
              router.push(
                loginState == "LOGIN"
                  ? {
                      pathname: "/course/[courseSlug]",
                      query: { courseSlug: props?.slug },
                    }
                  : {
                      pathname: "/login",
                      query: {
                        url: encodeURIComponent(`/course/${props?.slug}`),
                      },
                    }
              );
            }

            if (tracker) {
              trackEvent(tracker);
            }
          }}
        >
          {(!isHovering || !(props as any)?.hover_content_url) && (
            <>
              {props.image_cover_url ? (
                <Image
                  src={props.image_cover_url}
                  width={305}
                  data-testid="card-featured_cover"
                  className={`object-cover w-full h-full object-center ${
                    isHovering ? "" : isSquare ? "" : "rounded-8"
                  }`}
                />
              ) : (
                <div
                  className={`bg-neutral-20 w-full aspect-[16/9] ${
                    isRow ? "h-full" : "md:full"
                  } ${isSquare ? "" : "rounded-8"}`}
                ></div>
              )}
            </>
          )}

          {isBusiness &&
            (router as any).pathname == "/pilih-kursus" &&
            !isUncheck && (
              <div
                className={
                  "absolute top-0 w-full h-full flex justify-end items-start p-8"
                }
              >
                <IconPlusCircle />
              </div>
            )}

          <div className={"absolute top-0 w-full h-full"}>
            <div className="h-full flex justify-center items-center">
              {/* <Images
                src={"/images/icon-play-grey.svg"}
                alt="playGrey"
                width={45}
                height={45}
              /> */}
              <IconPlayCircle size={45} classes="m-auto" isBgCustomized />
            </div>
          </div>

          {isHovering && (props as any)?.hover_content_url && (
            <div className="w-full h-full">{content()}</div>
          )}

          {isHide && (
            <div className="absolute top-0 bg-[#00000099] w-full h-full flex justify-center items-center">
              <div className="text-b1 font-black text-white">
                Khusus goKampus Business
              </div>
            </div>
          )}
        </div>

        {isRow ? (
          <div
            className={`flex flex-col gap-12 ${
              isSquare ? "w-[90%] mx-auto" : "w-full md:w-[60%]"
            }`}
          >
            {/* card info and skills */}
            <div
              className={`overflow-hidden ${!isHovering ? "rounded-b-12" : ""}`}
            >
              <div
                className={`gap-8 relative text-neutral80_to_dark10 items-center`}
              >
                <div className={"flex flex-col justify-betweenm gap-8"}>
                  <Link
                    key={props?.name + index}
                    // @ts-ignore
                    href={
                      hasBusinessCourse === "true"
                        ? "https://business.gokampus.com/hubungi-sales"
                        : loginState == "LOGIN"
                        ? isRecommendPage
                          ? {
                              pathname: "/r/[page]",
                              query: {
                                page: `/course/${props.slug}`,
                              },
                            }
                          : {
                              pathname: "/course/[courseSlug]",
                              query: isSectionPage
                                ? {
                                    courseSlug: props?.slug,
                                    institutionKey: `${props?.institution.slug}`,
                                    hasBusinessCourse:
                                      props?.institution.is_business,
                                    institutionName: `${props?.institution.name}||${props?.institution.image_url}`,
                                  }
                                : props?.is_institution_business
                                ? {
                                    courseSlug: props?.slug,
                                    institutionKey: props?.institution,
                                    hasBusinessCourse:
                                      props?.is_institution_business,
                                    institutionName: `${props?.institution.name}||${props?.institution.image_url}`,
                                  }
                                : {
                                    courseSlug: props?.slug,
                                  },
                            }
                        : {
                            pathname: "/login",
                            query: isSectionPage
                              ? {
                                  url: encodeURIComponent(
                                    `/course/${props?.slug}?institutionKey=${props?.institution.slug}&hasBusinessCourse=${props?.institution.is_business}&institutionName=${props?.institution.name}||${props?.institution.image_url}`
                                  ),
                                }
                              : {
                                  url: encodeURIComponent(
                                    `/course/${props?.slug}?institutionKey=${props?.institution}&hasBusinessCourse=${props?.is_institution_business}`
                                  ),
                                },
                          }
                    }
                    passHref={hasBusinessCourse === "true"}
                    rel={
                      hasBusinessCourse === "true" ? "noopener noreferrer" : ""
                    }
                    target={hasBusinessCourse === "true" ? "_blank" : ""}
                  >
                    <h3
                      className={
                        `text-b1 font-medium max-w-full text-neutral-80 text-left ` +
                        (isMyClassPage ? "line-clamp-1" : "line-clamp-2")
                      }
                    >
                      {props.name}{" "}
                      {(isPpdsmAparaturTenant ||
                        isMenpanTenant ||
                        isWonderfulLearningTenant ||
                        isKemkesTenant ||
                        isGokAresTenant) && (
                        <span className="text-purple50_to_dark10">
                          ({learningHour} JP)
                        </span>
                      )}
                    </h3>
                  </Link>

                  <div className="flex gap-8 items-center">
                    {institutionImage && (
                      <LogoChip
                        image_url={institutionImage}
                        size={36}
                        useResizeImage={true}
                        styles="border border-neutral20_to_dark30"
                      />
                    )}

                    <div className="grid gap-4">
                      <div className="text-b2 font-light max-w-full truncate text-neutral80_to_dark10 text-left">
                        {institutionName}
                      </div>

                      <div className="flex gap-8 justify-start items-center text-b2 text-neutral80_to_dark10 font-light">
                        {typeof props?.review_summary?.star_avg === "number" &&
                        props?.review_summary?.star_avg !== 0 &&
                        !isB2B ? (
                          <>
                            <div className="flex items-center justify-center">
                              <IconStar size={14} fill color="#EFB443" />
                              <div className="ml-2 text-b2">
                                {formatRating(
                                  props?.review_summary?.star_avg.toFixed(1)
                                )}
                              </div>
                            </div>
                            |
                          </>
                        ) : (
                          <></>
                        )}
                        {props.total_learners !== 0 && !isB2B && (
                          <div>{`${numberWithCommas(props.total_learners)} ${t(
                            "pelajar"
                          ).toLowerCase()}`}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {props.skills.length > 0 ? (
                <div
                  className={`opacity-100 transition-opacity duration-[1000ms] ease-in-out`}
                >
                  <div className="flex flex-wrap gap-12 items-center">
                    {props?.skills?.slice(0, index).map((skill) => (
                      <Chip
                        key={skill.slug}
                        className={
                          (isModal ? "bg-neutral10_to_dark90" : "") +
                          " text-neutral80_to_dark10 font-light text-b2"
                        }
                      >
                        {skill.name || skill.toString()}
                      </Chip>
                    ))}

                    {lengthOfSkill >= 1 && (
                      <div className="text-neutral80_to_dark10 text-b2 font-light">
                        +{lengthOfSkill} Skill lainnya
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="line-clamp-2 text-neutral-70 font-light text-b2">
              <span
                dangerouslySetInnerHTML={{ __html: props?.description }}
              ></span>
            </div>

            {isLoading && chapters.length == 0 ? (
              <SkeletonRect className="w-full h-[76px] rounded-8"></SkeletonRect>
            ) : !isLoading && chapters.length > 0 ? (
              <AccordionWithImage
                className="bg-slated_purple rounded-8 p-8"
                data={chapters}
                countLesson={countLesson}
              />
            ) : null}
          </div>
        ) : (
          <>
            {/* card info and skills */}
            <div
              className={`overflow-hidden flex gap-8 ${
                !isHovering ? "rounded-b-12" : ""
              } ${isSquare ? "w-[90%] mx-auto" : "w-full m-0"}`}
            >
              <div className="flex justify-center items-start">
                {institutionImage && (
                  <LogoChip
                    image_url={institutionImage}
                    size={36}
                    useResizeImage={true}
                    styles="border border-neutral20_to_dark30"
                  />
                )}
              </div>
              <div className="flex flex-col gap-8 w-[-webkit-fill-available]">
                <h3
                  className={
                    `text-b1 font-medium max-w-full text-neutral-80 text-left ` +
                    (isMyClassPage ? "line-clamp-1" : "line-clamp-2")
                  }
                >
                  {props.name}{" "}
                  {(isPpdsmAparaturTenant ||
                    isMenpanTenant ||
                    isWonderfulLearningTenant ||
                    isKemkesTenant ||
                    isGokAresTenant) && (
                    <span className="text-purple50_to_dark10">
                      ({learningHour} JP)
                    </span>
                  )}
                </h3>

                <div className="text-b2 font-light max-w-full truncate text-neutral80_to_dark10 text-left">
                  {institutionName}
                </div>

                <div>
                  {props.skills.length > 0 ? (
                    <div
                      className={`opacity-100 transition-opacity duration-[1000ms] ease-in-out`}
                    >
                      <div className="flex flex-wrap gap-12 items-center">
                        {props?.skills?.slice(0, index).map((skill) => (
                          <Chip
                            key={skill.slug}
                            className={
                              (isModal ? "bg-neutral10_to_dark90" : "") +
                              " text-neutral80_to_dark10 font-light text-b2"
                            }
                          >
                            {skill.name || skill.toString()}
                          </Chip>
                        ))}

                        {lengthOfSkill >= 1 && (
                          <div className="text-neutral80_to_dark10 text-b2 font-light">
                            +{lengthOfSkill} Skill lainnya
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>

                {isMyClass && (
                  <>
                    {hasPaywall &&
                    !hasUserPaid &&
                    isCourseFinished &&
                    // (isEnterprisePlus || hasCertificate) ? (
                    // remove validate OR
                    isEnterprisePlus &&
                    hasCertificate ? (
                      <Button
                        variant="primary"
                        size="medium"
                        isFull
                        className="px-0"
                      >
                        {t("downloadCertif")}
                      </Button>
                    ) : (
                      <div className="flex flex-col items-center gap-8 py-8">
                        <div className="w-full h-6 bg-purple-10 rounded-full overflow-hidden relative">
                          <div
                            className={`rounded-full h-full ${
                              isCourseFinished ? "bg-green-50" : "bg-purple-50"
                            }`}
                            style={{
                              width:
                                (props.enrollment_progress.finished /
                                  props.enrollment_progress.total) *
                                  100 +
                                "%",
                            }}
                          ></div>
                        </div>

                        <div
                          className={`w-full text-b2 ${
                            isCourseFinished
                              ? "text-green-50 font-light text-b2"
                              : "text-purple-50 font-light"
                          }`}
                        >
                          {isCourseFinished ? (
                            <p>Selesai</p>
                          ) : (
                            <>
                              {props.enrollment_progress?.finished}/
                              {t("totalLesson", {
                                count: props.enrollment_progress?.total,
                                additionalS:
                                  props.enrollment_progress?.total > 1
                                    ? "s"
                                    : "",
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {(props as any).isChecked ? (
          <div className="absolute bg-[#0d0d0d80] top-0 rounded-8 w-full h-full p-8 flex justify-end items-start">
            <IconCheckCircle />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CardCourseWithLesson;
